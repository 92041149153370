/* Engagement Button Styles */
.engagement-button-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 17px;
  background-color: #ffffff;
  border-radius: 17px !important; /* Increase specificity */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent !important;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure border-radius applies to child elements */
}

.engagement-button-style:hover {
  border-color: #007bff !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Maap styling if needed */
.engagement-button-style.maap {
  border-color: #00a35c !important;
}


/* Add blue tint when selected */
.engagement-button-style.selected {
  background-color: #f2f8fd; /* Light blue tint */
  border-color: #007bff !important; /* Optional: blue border */
}

/* Disabled button styling */
.engagement-button-style.disabled {
  pointer-events: none;
  background-color: #eeeeee;
  color: #b0b0b0;
  cursor: not-allowed;
  border-color: #d0d0d0;
}

.title {
  margin: 0;
  font-weight: bold;
  font-size: 1.1em; /* Reduced font size for a smaller title */
}

/* Body Styling */
.body {
  margin: 1px;
  margin-top: 5px;
  font-size: 0.9em; /* Match font size with the rest of the page */
  color: #333;
  text-align: left;
}


/* Icon Styling */
.embed-responsive {
  width: 64px;
  height: 64px;
}