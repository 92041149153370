
.mongoLogoStyle {
  padding-left: 10px;
}

.mainText {
    font-family: "Euclid Circular A", sans-serif;
    font-size: 70px;
    color: #00A35C
}

.bodyFontStyle {
    color: #00A35C;
    margin-top: 15px;
}

.buttonStyle {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.5em 1em;
    border-radius: 2em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s ease-in-out;
    height: 10%;
  }
  
  .buttonStyle:hover {
    transform: scale(1.02);
  }
  
 .buttonStyle:focus {
    transform: scale(1.02);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.23);
  }