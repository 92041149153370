.bg-mongodb {
  background-image: url('https://ok5static.oktacdn.com/fs/bco/7/fs0adsfr3atbxC6nb297');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
}