.admin-ui-container {
    width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  
  .admin-ui-container h2 {
    color: #333333;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #666666;
  }
  
  .input-group select {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }
  
  .input-group select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #666666;
  }
  
  .checkbox input {
    margin-right: 5px;
  }
  
  .save-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }