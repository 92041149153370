.form-hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image:  url("../../assets/banner.png");
  
    /* Set a specific height */
    height: 70%;
  
    /* Position and center the image to scale nicely on all screens */
    background-position: 35% 85%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 2%;
  }

.titleStyle {
    text-decoration: none;
    color: white;
}

.titleStyle:hover {
    color :#00A35C;
}

.form-hero-text {
    text-align: center;
    color: white;
    font-family: 'Euclid Circular A', sans-serif;
}
.form-div {
    width: 40%;
    padding-top: 2%;
    margin-left: 30%;
}

.stepper-div {
    padding-bottom: 5%;
}

.datePicker {
    text-align: center;
    width: 100%;
    border-radius: 5px;
    border-color: black;
    border-width: 0.5px;
}

.bannerStyle {
    margin-top: 10px;
}

.fieldMargin {
    margin-bottom: 10px;
}

.navigationRow {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #E8EDEB;
}
.navigationStyle {
    color: black;
    font-size: 15px;
}

.navigationRefStyle {
    color: black;
    text-decoration: none;
}

.navigationRefStyle:hover {
    color: black;
    text-decoration: underline;
}


#descriptionTextArea {
    width: 100%;
    margin: 0;
    padding: 8px 12px 1px 12px;
    min-height: 80px;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    border: 1px solid;
    border-radius: 6px;
    font-family: "Euclid Circular A", sans-serif;
    transition: 500ms ease-in-out;
    transition-property: border-color, box-shadow;
    margin-top: 4px;
}

#description-label {
    margin-bottom: 0px;
}

.combobox-container {
    display: flex;
    justify-content: space-between;
  }
  
  .combobox-container > * {
    flex: 1;
    margin-right: 10px; /* Add some spacing between the components */
  }
  
  .combobox-container > *:last-child {
    margin-right: 0; /* Remove the margin from the last component */
  }

  .button-container {
    display: flex;
  }
  
  .initial {
    font-weight:400
  }
  .larger {
    text-align: center;
    justify-content: center;
    height: 550px
  }

  .duration-button {
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 0;
    height: 45px; /* Adjust the height as needed */
    border: 1px solid #889396; /* Adjust the border color as needed */
    border-right: none; /* Remove the right border to create a seamless row */
    border-left: none; /* Remove the right border to create a seamless row */
  }
  
  .duration-button:first-child {
    border-left: 1px solid #889396; /* Add the right border back for the last button */
    border-top-left-radius: 20px; /* Adjust the radius as needed */
    border-bottom-left-radius: 20px; /* Adjust the radius as needed */
  }
  
  .duration-button:last-child {
    border-right: 1px solid #889396; /* Add the right border back for the last button */
    border-top-right-radius: 20px; /* Adjust the radius as needed */
    border-bottom-right-radius: 20px; /* Adjust the radius as needed */
  }
  
  /* Add this to ensure the buttons do not stretch larger than their container */
  .button-container {
    overflow: hidden;
  }
  
  .duration-button:hover {
    background-color: #016AF8;
    color: #FFFFFF;
    border: 3px solid #FFFFFF;
    
  }
  
  .duration-button.active {
    background-color: #E1F6FF;
    border-color: #016AF8;
    border: 3px solid #016AF8;
  }

  
  .container {
    display: flex;
    align-items: center; /* Centers items vertically */
    width: 100%; /* Ensure the container takes full width */
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  .input-field {
    flex-grow: 1; /* Allow the input field to take up the remaining space */
    width: 100%; /* Make sure the input takes the full width of its flex item */
  }
  
  .my-checkbox {
    display: flex; /* Aligns label and checkbox horizontally */
    align-items: center; /* Centers the checkbox and label vertically */
    width: 150px; /* Set a fixed width for the checkbox container */
    margin-left: 15px; /* Space between the input and the checkbox */
    margin-bottom: 10px;
}
