.embed-responsive .card-img-top {
  object-fit: cover;
  width: 100%;
  height: 190px;
}

.card-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.5em; 
}

.body {
  margin-bottom: 5px;
}

.card-styles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-width:1.5px;
  border-color:transparent;
}

.maap {
  border-color: #00a35c;
  border-width: 1.5px;
}

.disabled {
  pointer-events: none; /* Makes the element unclickable */
  background-color: #eeeeee; /* Grey background */
  color: #b0b0b0; /* Grey text */
  cursor: not-allowed; /* Changes the cursor to indicate it is disabled */
  border-color: #d0d0d0; /* Optional: grey border color */
}


.colorCard {
  background-color: #c0fae6;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-styles:hover {
  border-color: #00a35c;
  border-width: 1.5px;
}

a {
  text-decoration: none;
}

.lowerImage {
  padding-top: 5%;
}
